import { render, staticRenderFns } from "./Stake.vue?vue&type=template&id=5bb50fac&scoped=true&"
import script from "./Stake.vue?vue&type=script&lang=js&"
export * from "./Stake.vue?vue&type=script&lang=js&"
import style0 from "./Stake.vue?vue&type=style&index=0&id=5bb50fac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb50fac",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
installComponents(component, {VChip,VChipGroup})
