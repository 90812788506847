<template>
  <section class="stake-section background">
    <div class="decoration-circles">
      <div class="circle"></div>
      <div class="circle-2"></div>
    </div>
    <div class="mt-4">
      <v-chip-group mandatory active-class="primary" v-model="validator">
        <v-chip
          v-for="(network, i) in validators"
          :key="i"
          class="network-chip"
          x-large
          :value="network"
          ><img class="mr-2" height="25px" :src="network.img" alt="" />
          {{ network.name }}
        </v-chip>
      </v-chip-group>
    </div>
    <!-- <ValidatorSelector />

    <v-container v-if="validator !== 'fetch'" class="small-container py-8">
      <v-row>
        <v-col cols="12" lg="4" md="4">
          <v-card class="backgroundDark" ref="tokenCard">
            <v-card-title>
              <img src="" alt="" />
              <h1>Avalanche</h1>
              <v-spacer></v-spacer>
              <v-chip color="backgroundLight" small class="text-uppercase"
                >Token</v-chip
              >
            </v-card-title>
            <v-card-text>
              <p class="mb-0">
                Avalanche is the fastest smart contracts platform in the
                blockchain industry, as measured by time-to-finality, and has
                the most validators securing its activity of any proof-of-stake
                protocol.
              </p>
            </v-card-text>

            <v-card-text>
              <v-row class="text-center">
                <v-col>
                  <h5>Price</h5>
                  <h3 class="white--text">
                    {{ tokenData.price | twoDecimals }}
                  </h3>
                </v-col>
                 <v-col>
                  <h5>24hs</h5>
                  <h3
                    class="white--text"
                    :class="
                      tokenData.change > 0 ? 'success--text' : 'error--text'
                    "
                  >
                    {{ tokenData.change | twoDecimals }}%
                  </h3>
                </v-col>
                <v-col>
                  <h5>Volume USD</h5>
                  <h3 class="white--text">
                    {{ tokenData.volume | volumeFormatter }}
                  </h3>
                </v-col>
                <v-col>
                  <h5>Rank #</h5>
                  <h3 class="white--text">
                    {{ tokenData.rank }}
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions
              class="backgroundMedium d-flex align-center px-8 py-4"
            >
              <v-text-field
                readonly
                v-model="avaxValidatorAddress"
                class="backgroundDark d-flex align-center mx-4 pt-0"
                rounded
                hide-details
                full-width
                label="Node ID"
              >
              </v-text-field>
              <v-btn
                @click="copyToClipboard(avaxValidatorAddress)"
                small
                color="primary"
                ><v-icon left x-small>{{
                  justCopied ? "mdi-check" : "mdi-content-copy"
                }}</v-icon
                >Copy</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3">
          <v-card class="backgroundDark">
            <apexchart
              :height="$refs.tokenCard ? $refs.tokenCard.clientHeight : 275"
              :options="options"
              :series="series"
            ></apexchart>
          </v-card>
        </v-col>
      </v-row>
    </v-container> -->
    <ValidatorData :validator="validator" />

    <div class="py-8 text-center">
      <h2>Staking Instructions</h2>
      <WalletSteps :wallets="validator.wallets" />
      <!-- <Steps /> -->
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import WalletSteps from "../components/Stake/WalletSteps.vue";
import ValidatorData from "../components/Stake/ValidatorData.vue";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

export default {
  components: {
    ValidatorData,
    WalletSteps,
  },
  metaInfo: {
    titleTemplate: "%s | Stake",
  },
  data() {
    return {
      justCopied: false,
      validator: {},
      validators: [
        {
          number: 1,
          validator: "fetch",
          token: "fet",
          name: "Fetch.ai",
          description: "step-one-instructions",
          img: "/img/fetch-iso-white.png",
          address: "fetch1p3l46e2ajm8aql0fayjuqxz92mepcng4l6t2c5",
          text: `
          Fetch.ai is building an open access, tokenized, decentralized
              machine learning network to enable smart infrastructure built
              around a decentralized digital economy. Fetch.ai’s network is
              based around an open-source technology that any user can run to
              connect to the network, giving access to the power of AI on a
              world-scale secure dataset, to carry out complex coordination
              tasks in the modern economy.`,
          wallets: [
            {
              name: "Ledger",
              logo: "/img/ledger-iso-white.png",
              extra: `For more detailed steps you can consult the <a target="_blank" href="https://fetch.ai/a-guide-on-many-different-ways-to-stake-fet/">Fetch Official Instructions</a>`,
              steps: [
                {
                  number: 1,
                  icon: "mdi-wallet",
                  title: "Install Wallets",
                  description:
                    "After setting up your device with a PIN a passphrase, you should install both Ethereum and Cosmos wallets through the Ledger Live App.",
                },
                {
                  number: 2,
                  icon: "mdi-google-chrome",
                  title: "Fetch Address",
                  description:
                    "You can then find your Fetch address by installing a Chrome Brower and then following this link to the block explorer on our test-net. The address can then be found following these next steps.",
                },
                {
                  number: 3,
                  icon: "mdi-connection",
                  title: "Connect your Ledger Nano",
                  description:
                    "Connect your Ledger Nano to your computer, unlock it with your PIN, and then open the Cosmos application on the device.",
                },
                {
                  number: 4,
                  icon: "mdi-earth",
                  title: "Visit Block Explorer",
                  description:
                    "User your Chrome browser to visit the block explorer at this site.",
                },
              ],
            },
            {
              name: "Cosmostation",
              logo: "/img/cosmostation-iso-white.png",
              extra: `For more detailed steps you can consult the <a target="_blank" href="https://fetch.ai/a-guide-on-many-different-ways-to-stake-fet/">Fetch Official Instructions</a>`,
              steps: [
                {
                  number: 1,
                  icon: "mdi-cellphone",
                  title: "Get Cosmostation Mobile Wallet",
                  description:
                    "Download and install Cosmostation mobile app on your app store.",
                  extra: `
              <a
              href="https://apps.apple.com/us/app/cosmostation/id1459830339"
              target="_blank" rel="noopener noreferrer"
              >
              <img style="max-width: 200px" src='/img/download-appstore.png'>
              </a>
              <a
              href="https://play.google.com/store/apps/details?id=wannabit.io.cosmostaion"
              target="_blank" rel="noopener noreferrer"
              >
              <img style="max-width: 200px" src='/img/download-playstore.png'>
              <a/>
              `,
                },
                {
                  number: 2,
                  icon: "mdi-wallet",
                  title: "Generate Address",
                  description:
                    "Generate your own address on the Cosmostation Wallet (on Fetch.ai chain).",
                },
                {
                  number: 3,
                  icon: "mdi-cash",
                  title: "Send FET from Exchange",
                  description:
                    "Buy FET on any exchange available -Binance for example- and transfer it to your new address generated on Cosmostation.",
                },
                {
                  number: 4,
                  icon: "mdi-earth",
                  title: "Delegate to Altostake Validator",
                  description:
                    "Delegate your FET tokens to our validator, you can find it on the list or using our Validator Address.",
                },
              ],
            },
          ],
        },
        {
          number: 1,
          validator: "avalanche",
          token: "avax",
          name: "Avalanche",
          description: "step-one-instructions",
          img: "/img/avalanche-iso-white.png",
          address: "NodeID-5PxpmHkfB3gNh3spZWuzEKis3DJ8WKxLt",
          text: `Avalanche is the fastest smart contracts platform in the blockchain industry, as measured by time-to-finality, and has the most validators securing its activity of any proof-of-stake protocol.`,
          wallets: [
            {
              name: "Avax",
              logo: "/img/avalanche-iso-white.png",
              extra: `For more detailed steps you can consult the <a target="_blank" href="https://docs.avascan.info/guide-delegation-avalanche-network/guide-delegation-avax-web-wallet">Avax Official Instructions</a>`,
              steps: [
                {
                  number: 1,
                  icon: "mdi-wallet",
                  title: "Import or create new wallet",
                  description: `Navigate to the official wallet of Avalanche platform. The AVAX web wallet is “a simple, secure, non-custodial wallet for storing Avalanche assets.” You can access AVAX web wallet: <a style="color: white" href="https://wallet.avax.network/">https://wallet.avax.network/</a> `,
                  extra: "",
                },
                {
                  number: 2,
                  icon: "mdi-google-chrome",
                  title: "Delegate AVAX to AltoStake",
                  description: `
                    <ol>
                      <li>Navigate to the “Earn” section on left-sidebar of AVAX web wallet.</li>
                      <li>Click “Add Delegator” to add your delegation.</li>
                      <li>Search for AltoStake validator with our <a href="#">Node ID</a></li>
                      <li>To submit your delegation, click on “Submit”</li>
                    </ol>
                    `,
                },
              ],
            },
          ],
        },
      ],
      avaxValidatorAddress: "NodeID-5PxpmHkfB3gNh3spZWuzEKis3DJ8WKxLt",
      tokenData: {},
      options: {
        chart: {
          id: "vuechart-example",
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          labels: {
            show: false,
            formatter: function (value) {
              return new Date(value).toLocaleDateString("en-US");
            },
          },
          lines: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        markers: {
          colors: ["#F44336", "#E91E63", "#9C27B0"],
        },
      },
      series: [
        {
          name: "series-1",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.stake-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-image: linear-gradient(
    -20deg,
    var(--v-backgroundMedium-base) 55%,
    var(--v-backgroundLight-base) 100%
  );
}

.decoration-circles {
  .circle {
    position: absolute;
    width: 400px;
    height: 400px;
    border-radius: 100%;
    background: var(--v-primary-base);
    bottom: 30%;
    right: 0px;
    z-index: 0;
  }
  .circle-2 {
    position: absolute;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    background: var(--v-primary-base);
    left: 10%;
    bottom: 20%;
    z-index: 0;
  }
}

@media (max-width: 768px) {
  .circle-2 {
    display: none;
  }
}

.stake-section h2 {
  font-size: 2rem;
  text-transform: uppercase;
}
.network-chip {
  border-radius: 10px;
  padding: 0.4rem 2rem;
}
</style>
